import './errorReport';
import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import './index.css';
import App from './App';
import Login from './components/login/Login';
import registerServiceWorker from './registerServiceWorker';

ReactDOM.render(
    <Router>
        <div>
            <Switch>
                <Route path='/Login' component={Login} exact/>
                <Route path='/' component={App} />
        </Switch>
        </div>
    </Router>,
    document.getElementById('root'));

registerServiceWorker();
