import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import Loading from '../../common/Loading';
import Pagination from '../../common/Pagination';

import './Table.css';

const Table = (props) => {
    const {
        title,
        data, data_rows, loading,
        edit, edit_root,
        detail_root, detail, detail_attr,
        custom_actions,
        no_action,
        top_paginate, page, totalPages, pageFunction } = props;
    return (
                        <div className="ibox float-e-margins">
                            {title &&
                                <div className="Table-Title ibox-title">
                                    <h5 className="Table-Heading">{title}</h5>
                                    {loading && <Loading className="pull-right Table-Loading" width={"14px"} height={"14px"} />}
                                    { totalPages > 1 && top_paginate && <Pagination totalPages={totalPages} page={page} pageFunction={pageFunction}/> }
                                </div>
                            }
                            <div className="ibox-content">
                                <table className="footable table table-stripped" data-page-size="8" data-filter="#filter">
                                    <thead>
                                    <tr>
                                        { data && data.length > 0 && data_rows && data_rows.map(
                                            (row_data, index) => (<th key={"th" + index}>{row_data.label}</th>)
                                        )}
                                        { data && data.length > 0 && !no_action && <th>Actions</th>}
                                    </tr>
                                    </thead>
                                    <tbody>
                                    { data && data.map((row, index) => (
                                        <tr key={index}>
                                            {data_rows.map( (row_data, data_index) => (
                                                <td key={index + "" + data_index} >{
                                                    row_data.transform ? (
                                                        row_data.transform(row[row_data.key])
                                                    ) : (
                                                        row[row_data.key]
                                                    )
                                                }</td>
                                            ))}
                                            {!no_action && edit && <td><Link to={edit_root + "/" + row.id + "/"}>Edit</Link></td>}
                                            {!no_action && detail && <td><Link to={detail_root + "/" + row[detail_attr] + "/"}>Details</Link></td>}
                                            {
                                                custom_actions && custom_actions.map( (render_block) => (
                                                    render_block(row)
                                                ))
                                            }
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                                { totalPages > 1 && <Pagination totalPages={totalPages} page={page} pageFunction={pageFunction}/>}
                            </div>
                        </div>
    )
}

Table.propTypes = {
    data: PropTypes.array,
    title: PropTypes.string,
}

export default Table;
