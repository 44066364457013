import decode from 'jwt-decode';
import autorefresh from 'jwt-autorefresh'

class AuthService {

    constructor(domain) {
        this.domain = domain;
        this.fetch_raw = this.fetch_raw.bind(this)
        this.fetch = this.fetch.bind(this)
        this.login = this.login.bind(this)
        this.refresh = this.refresh.bind(this)
        this.getProfile = this.getProfile.bind(this)

        this.cancel()
        if(this.hasRefresh()){
            this.startAutoRefresh(this.getToken());
        }
    }

    login(username, password){
        return this.fetch(`${this.domain}/token/`, {
            method: 'POST',
            body: JSON.stringify({
                username,
                password,
            })
        }).then(res => {
            this.setToken(res.access);
            this.setRefresh(res.refresh);
            this.cancel()
            this.startAutoRefresh(res.access);
            return Promise.resolve(res);
        });
    }

    refresh(){
        const headers = {
            "Accept": "application/json",
            "Content-Type": "application/json",
        }
        const refresh = this.getRefresh();

        return fetch(`${this.domain}/token/refresh`, {
            headers,
            method: 'POST',
            body: JSON.stringify({
                refresh,
            })
        })
        .then(this._checkStatus)
        .then(response => response.json().then(json => response.ok ? json : Promise.reject(json)))
        .then(res => {
            console.log("Refresh done");
            this.setToken(res.access);
            return Promise.resolve(res.access);
        })
        .catch((error) => {
            console.log("Refresh failed: " + error.message);
            this.logout();
        });
    }

    fetch_raw(url, options) {
        const headers = {
            "Accept": "application/json",
            "Content-Type": "application/json",
        }

        if(this.loggedIn()) {
            headers["Authorization"] = "Bearer " + this.getToken();
        }

        return fetch(url, {
            headers,
            ...options,
        })
    }

    fetch(url, options) {
            return this.fetch_raw(url, options)
            .then(this._checkStatus)
            .then(response => response.json().then(json => response.ok ? json : Promise.reject(json)));
    }

    _checkStatus(response) {
        if (response.status >= 200 && response.status < 300) {
            return response;
        } else {
            var error = new Error(response.statusText);
            error.response = response;
            throw error;
        }
    }

    loggedIn() {
        const token = this.getToken();
        return !!token && token !== "undefined" && !this.isTokenExpired(token);
    }

    hasRefresh() {
        const token = this.getRefresh();
        return !!token && token !== "undefined" && !this.isTokenExpired(token);
    }

    isTokenExpired(token){
        try {
            const decoded = decode(token);
            if (decoded.exp < Date.now() / 1000) {
                return true;
            } else {
                return false;
            }
        } catch(err) {
            return false;
        }
    }

    setToken(idToken){
        localStorage.setItem('id_token', idToken)
    }

    getToken() {
        return localStorage.getItem('id_token');
    }

    setRefresh(idRefresh){
        localStorage.setItem('id_refresh', idRefresh)
    }

    getRefresh() {
        return localStorage.getItem('id_refresh');
    }

    logout() {
        this.stopAutoRefresh();
        localStorage.removeItem('id_token');
        localStorage.removeItem('id_refresh');
    }

    getProfile() {
        return decode(this.getToken());
    }

    // taken from jwt_autorefresh
    leadSeconds() {
      const jitter = Math.floor(Math.random() * 30)

      /** Schedule autorefresh to occur 60 to 90 seconds prior to token expiration */
      return 60 + jitter
    }

    cancel = () => {}
    startAutoRefresh (accesstoken) {
        const {refresh, leadSeconds} = this;
        const start = autorefresh({refresh, leadSeconds });
        this.cancel();
        this.cancel = start(accesstoken)
        console.log("[JWT] AutoRefresh started");
    }

    stopAutoRefresh() {
        this.cancel();
        console.log("[JWT] AutoRefresh stopped");
    }
}

export default AuthService;
