import React from 'react';
import Moment from 'react-moment';
import FontAwesome from 'react-fontawesome';

import { API_URL } from '../../config';
import Loading from '../common/Loading';
import Table from '../inspinia/components/Table';

import RoomSearch from './RoomSearch';

import './UnassignedValves.css';

/**
 * This component is a self contained widget for showing unassigned valves and has functionality to assign them to a room.
 * If there are no unassigned valves, this widget just shows a message to the user.
 */
class UnassignedValves extends React.Component {

    constructor() {
        super();

        this.state = {
            // Data related
            loading: false,
            data: [],

            // Pagination related
            offset: 0,
            count: 0,
            limit: 25,

            // Selection related
            valve: null,
            room: null,
            assign_loading: false,
        };

        // Configuration of table columns and formats
        this.data_rows =  [
            {
                label: "Gateway",
                key: "gateway",
                transform: (id) => {return this.props.cache.get("gateways", id, this, "name") }
            },
            {
                label: "Name",
                key: "name",
            },
            {
                label: "Last Updated",
                key: "last_updated",
                transform: (value) => {return <Moment date={value} format={"YYYY/MM/DD HH:mm"} />}
            },
        ];

        this.loadData = this.loadData.bind(this);
        this.pageFunction = this.pageFunction.bind(this);
        this.assignRoom = this.assignRoom.bind(this);
    }

    componentDidMount(){
        this.loadData();
    }

    loadData(){
        const { auth } = this.props;
        const { offset, limit } = this.state;

        this.setState({loading: true});

        auth.fetch(`${API_URL}/valves/unassigned/?limit=${limit}&offset=${offset}`)
            .then((response)=>{
                this.setState({
                    loading: false,
                    data: response.results,
                    count: response.count,
                });
            })
            .catch((error) => {
                this.setState({
                    loading: false,
                    error: error.message,
                });
            });
    }

    pageFunction(dir){
        const { count, limit, offset } = this.state;
        let newOffset = offset;

        if (dir === "next"){
            if (offset < count){
                newOffset += limit;
            }
        } else if (dir === "prev"){
            if (offset > 0){
                newOffset = Math.max(0, offset - limit);
            }
        }

        if (newOffset !== offset){
            this.setState({offset: newOffset}, () => {
                this.loadData();
            });
        }
    }

    assignRoom(){
        const { room, valve } = this.state;
        const { auth } = this.props;

        if ( room === null || valve === null)
            return;

        const headers = {
            "Accept": "application/json",
            "Content-Type": "application/json",
        }

        this.setState({assign_loading: true});

        auth.fetch(`${API_URL}/valves/${valve.id}/`, {
            method: "PUT",
            body: JSON.stringify({
                ...valve,
                room,
            }),
            ...headers,
        })
        .then((response) => {
            this.setState({
                valve: null, room: null,
                assign_loading: false
            });
            this.loadData();
        })
        .catch((error) => {
            this.setState({
                error: error.errorMessage,
                assign_loading: false
            });
        });
    }


    render() {
        const { auth } = this.props;
        const { data, loading, count, limit, offset, valve, assign_loading } = this.state;
        const { data_rows } = this;

        let totalPages = Math.ceil(count / limit);
        let page = (offset / limit) + 1;

        return (
            <div className="ibox float-e-margins">
                <div className="ibox-title">
                    { loading && <span className="pull-right"><Loading width={"14px"} height={"14px"} /></span>}
                    <h5>Unassigned Valves</h5>
                </div>
                { !loading && <div className="ibox-content">
                    { data.length > 0 ? (
                        <div>
                            { /* This Table widget is configured in the constructor and has a custom render action to select a valve.
                                This then triggers other UI changes by bringing up the select box */ }
                            <Table
                                title={""} data={data} data_rows={data_rows}
                                edit={false} detail={false} loading={false}
                                page={page} totalPages={totalPages} pageFunction={this.pageFunction} top_paginate={true}
                                custom_actions={
                                    [
                                        (valve_data) => {
                                          return (
                                              <td key={`${valve_data.id}-custom`}>
                                                  <button
                                                    className="btn btn-sm btn-info UnassignedValves-btn"
                                                    onClick={() => this.setState({valve: valve_data})}>Assign</button>
                                              </td>
                                          );
                                        }
                                    ]
                                }
                            />
                        </div>
                        ) : (
                            <div className="well well-lg text-center">
                                <h3>
                                    No Unassigned Valves
                                </h3>
                                Great, all valves are accounted for in your organisation.
                            </div>
                        )
                    }
                </div> }
                { /* This is the rendering for the pop up window for selecting a room */}
                { valve &&
                    <div className="UnassignedValves-overlay animated fadeIn">
                        <div className="row">
                            <div className="col-md-8 col-md-offset-2">
                                <div id="UnassignedValves-assign" className="ibox float-e-margins animated fadeInUp">
                                    <div className="ibox-title">
                                        <h5>Assign Valve to Room</h5>
                                        <div className="ibox-tools">
                                            <FontAwesome name="times" onClick={() => this.setState({valve: null, room: null})} />
                                        </div>
                                    </div>
                                    <div className="ibox-content">
                                        <div className="row">
                                            <div className="col-md-5 horizontal-center">
                                                { /* Dropdown widget that uses an autocomplete interaction to select a room */ }
                                                <RoomSearch auth={auth} action={(room_id) => this.setState({room: room_id})} />
                                            </div>
                                            <div className="col-md-5 horizontal-center">
                                                { /* This is used as a small inline room info widget. It uses nested cache calls /* }
                                                <table className="UnassignedValves-room-info">
                                                    <tbody>
                                                        <tr>
                                                            <td>Building: </td>
                                                            <td>{cache.get(
                                                                "buildings",
                                                                cache.get(
                                                                    "floors",
                                                                    cache.get("rooms", room, this, "floor"),
                                                                    this,
                                                                    "building"
                                                                ),
                                                                this,
                                                                "name"
                                                            )}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Floor: </td>
                                                            <td>{cache.get(
                                                                "floors",
                                                                cache.get("rooms", room, this, "floor"),
                                                                this,
                                                                "name"
                                                            )}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div className="col-md-2 horizontal-center">
                                                { /* This calls the function which assigns a valve to a room */ }
                                                {assign_loading ? <Loading /> : (
                                                    <button
                                                        className="btn btn-sm btn-info UnassignedValves-assign-btn"
                                                        onClick={() => this.assignRoom()}>Assign</button>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        );
    }
}

export default UnassignedValves;
