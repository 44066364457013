/**
 * API root url
*/
export const API_URL = 'https://cloud.aeriformtech.com/api';
export const READINGS_ENDPOINT = 'readings/';

export const MIN_SETPOINT = 17;
export const MAX_SETPOINT = 23;
export const USER_CONSOLE_URL = "https://aeriform-user.herokuapp.com";

export const FORM_CONFIG = {
    building: [
                {
                    name: "Name",
                    api_name: "name",
                    placeholder: "The Annex",
                    label: "The name of the building",
                    type: "inputText",
                },
    ],
    floor: [
                {
                    name: "Building",
                    api_name: "building",
                    endpoint: "buildings/",
                    value_key: "id",
                    label_key: "name",
                    label: "Building this floor is on",
                    type: "select",
                },
                {
                    name: "Name",
                    api_name: "name",
                    placeholder: "Ground Floor",
                    label: "The name of the Floor",
                    type: "inputText",
                }
    ],
    room: [
                {
                    name: "Floor",
                    api_name: "floor",
                    endpoint: "floors/",
                    value_key: "id",
                    label_key: "name",
                    label: "Floor this room is on",
                    type: "select",
                },
                {
                    name: "Name",
                    api_name: "name",
                    placeholder: "The Rumbus Room",
                    label: "The name of the Room",
                    type: "inputText",
                },
                {
                    name: "Type",
                    api_name: "type",
                    placeholder: "PR",
                    label: "Use PR for now",
                    type: "inputText",
                },
                {
                    name: "Initial Set Point",
                    api_name: "set_point",
                    placeholder: 19,
                    label: "The temperature that the room should be",
                    min: 17,
                    max: 25,
                    type: "inputNumber",
                }
    ],
    action: [
        {
            name: "Set Point",
            api_name: "set_point",
            placeholder: 19,
            label: "The temperature that the room should be",
            min: 17,
            max: 25,
            type: "inputNumber",
        }
    ]
};

export const RESET_BUTTON_PROPS = {
    workflow: 1,
    label: "Reset Config"
};

export const REFERENCERUN_BUTTON_PROPS = {
    workflow: 2,
    label: "Reference Run"
};


export const TEACHIN_BUTTON_PROPS = {
    workflow: 3,
    label: "Teach-In"
};

export const CONFIGPIR_BUTTON_PROPS = {
    workflow: 4,
    label: "Config PIR"
};

export const ALERT_LABELS = {
    "stale_gateway": "Stale Gateway Comms.",
    "stale_valve": "Stale Valve Comms.",
    "fake_closed_valve": "Unexplained Temp. Increase",
    "static_temperature_valve": "Temperature Unchanged",
    "wrong_setpoint_valve": "Failed Teach-in",
    "low_battery_valve": "Low Battery"
}
