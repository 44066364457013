import React, {Component} from 'react';
import {Link} from 'react-router-dom';

import {API_URL} from '../../config';
import Form from '../inspinia/components/Form';
import Loading from '../common/Loading';

import {renderInputNumber, renderInputText } from '../common/FormUtils';
import SelectRestList from '../common/SelectRestList';

class AddView extends Component {

    constructor(props){
        super(props);

        this.state = {
            loading: true,
            error: null,
            form_data: null
        };

        this.title = "Add New"
        this.method = "POST"

        this.submitData = this.submitData.bind(this);
        this.buildFormData = this.buildFormData.bind(this);
        this.renderHeader = this.renderHeader.bind(this);

        this.changeFunc = (event) => {
            let { form_data } = this.state;
            let { target } = event;
            form_data.inputs = form_data.inputs.map(
                (input) => {
                    if (input.api_name === target.name){
                        input.value = target.value
                    }
                    return input;
                }
            );
            this.setState({[event.target.name]: event.target.value, form_data})
        };

        this.renderInputText = renderInputText;
        this.renderInputNumber = renderInputNumber;

        this.renderSelect = (input_data) => {
            const { api_name, change, endpoint, value_key, label_key, value } = input_data;
            const { auth } = this.props;
            return (
                <SelectRestList auth={auth} api_name={api_name} endpoint={endpoint}
                    value_key={value_key} label_key={label_key} change={change} value={value} />
                )
        };

    }

    componentWillMount(){
        this.buildFormData();
    }

    buildFormData(){

        const { form_config } = this.props;

        let change = this.changeFunc;
        let render = {
            inputText: this.renderInputText,
            select: this.renderSelect,
            inputNumber: this.renderInputNumber
        }

        let form_data = {
            inputs: form_config.map(
                (input) => {
                    return Object.assign({ change, render: render[input.type] }, input)
                }),
            submit: {
                label: "SUBMIT",
                callback: (event) => {
                    let data = {};
                    form_config.forEach((conf) => {
                        data[conf.api_name] = this.state[conf.api_name];
                    });
                    this.submitData(event, data );
                }
            }
        };
        this.setState({ form_data, loading: false});
    }

    submitData(event, data){
        const { auth, history, endpoint, redirect } = this.props;
        const { method } = this;

        const organisation = auth.getProfile().organisation;

        data = Object.assign({ organisation }, data);

        const headers = {
            "Accept": "application/json",
            "Content-Type": "application/json"
        };

        event.preventDefault();

        this.setState({loading: true})

        let url = `${API_URL}/${endpoint}`;
        if (this.method === "PUT"){
            const {id} = this.props.match.params;
            url = `${API_URL}/${endpoint}${id}`;
        }

        auth.fetch(url, {
            method: method,
            body: JSON.stringify(data),
            ...headers
        })
        .then((response) => {
            this.setState({loading: false});
            history.push(redirect);
        })
        .catch((error) => {
            this.setState({
                error: error.message,
                loading: false
            });
        });

    }

    renderHeader() {
        const { listTitle, redirect } = this.props;
        return (
            <div className="row wrapper border-bottom white-bg page-heading">
                <div className="col-lg-10">
                    <h2>{listTitle}</h2>
                    <ol className="breadcrumb">
                        <li><Link to="/">Home</Link></li>
                        <li><Link to={redirect}>{listTitle}</Link></li>
                        <li className="active"><strong>New</strong></li>
                    </ol>
                </div>
                <div className="col-lg-2">
                </div>
            </div>
        );
    }

    render(){
        const { title } = this;
        const { desc } = this.props;
        const { loading, form_data } = this.state;

        if (loading && !form_data) {
            return (
                <div>
                    {this.renderHeader()}
                    <div className="wrapper wrapper-content">
                        <div className="row">
                            <div className="col-lg-4 col-lg-offset-5">
                                <Loading width={"100px"} height={"100px"} />
                            </div>
                        </div>
                    </div>
                </div>
            )
        }

        return (
            <div>
                {this.renderHeader()}
                <Form title={title} desc={desc} form_data={form_data} loading={loading}/>
            </div>
        );
    }
}

export default AddView;
