import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

const TopHeader = (props) => {
    const { handleLogout, routes } = props;

    return (
        <div className="row border-bottom">
            <nav className="navbar navbar-static-top white-bg" style={{marginBottom: 0}}>
                <ul className="nav navbar-top-links navbar-right hidden-sm hidden-xs">
                    <li>
                        <a onClick={handleLogout}>
                            <i className="fa fa-sign-out"></i> Log out
                        </a>
                    </li>
                </ul>

                <div className="navbar-header">
                    <a className="navbar-minimalize minimalize-styl-2 btn btn-primary hidden-sm hidden-md hidden-lg"
                        data-toggle="collapse" data-target="#top-menu" ><i className="fa fa-bars"></i> </a>
                </div>
                <ul className="nav nav-stacked navbar-right hidden-sm hidden-md hidden-lg collapse" id="top-menu" style={{clear: "both"}}>
                    {routes.map((route, index) => (
                        route.menu &&
                            <li key={index}>
                                <Link to={route.path}><i className="fa fa-th-large"></i> <span className="nav-label">{route.name}</span></Link>
                            </li>
                    ))};
                    <li role="separator" className="divider"><hr /></li>
                </ul>
                <ul className="nav navbar-right hidden-sm hidden-md hidden-lg">
                    <li className="pull-right">
                        <a onClick={handleLogout}>
                            <i className="fa fa-sign-out"></i> Log out
                        </a>
                    </li>
                </ul>
            </nav>
        </div>
    )
}

TopHeader.propTypes = {
    handleLogout: PropTypes.func,
};

export default TopHeader;
