import React from 'react';
import FontAwesome from 'react-fontawesome';
import AuthService from '../services/AuthService';
import { API_URL, USER_CONSOLE_URL } from '../../config';

import './Login.css';

class Login extends React.Component {

    constructor(){
        super();

        this.state = {
            error: false,
            error_msg: "",
            username: "",
            password: "",
        };

        this.Auth = new AuthService(API_URL);

        this.handleChange = this.handleChange.bind(this);
        this.handleFormSubmit = this.handleFormSubmit.bind(this);
    }

    componentWillMount(){
        if(this.Auth.loggedIn())
            this.props.history.replace('/');
    }

    handleChange(event){
        this.setState({
            [event.target.name]: event.target.value,
        });
    }

    handleFormSubmit(event){
        event.preventDefault();

        const { username, password } = this.state;

        this.setState({error: false});

        this.Auth.login(username, password)
            .then(res => {
                if (this.Auth.getProfile().is_org_admin){
                    this.props.history.replace('/');
                } else {
                    this.setState({
                        error: true,
                        error_msg: "Not An Admin User",
                        username: "",
                        password: "",
                    });
                }
            })
            .catch(err => {
                this.setState({
                    error: true,
                    error_msg: "Incorrect User/Password.",
                    username: "",
                    password: "",
                });
            });
    }

    render(){
        const {error, error_msg, username, password} = this.state;

        return (
            <div>
                {error &&
                    <div className="Login-error">
                        <FontAwesome
                            className="Login-error-icon"
                            name="exclamation-triangle"
                        />
                        <div className="Login-error-text">
                            {error_msg}
                        </div>
                    </div>
                }
                <div className="Login-container">
                    <div className="Login-card">
                        <h1>Login</h1>
                        <form onSubmit={this.handleFormSubmit}>
                            <input
                                className="Login-form-item"
                                placeholder="Username"
                                name="username"
                                type="text"
                                onChange={this.handleChange}
                                value={username}
                            />
                            <input
                                className="Login-form-item"
                                placeholder="Password"
                                name="password"
                                type="password"
                                onChange={this.handleChange}
                                value={password}
                            />
                            <input
                                className="Login-form-submit"
                                value="SUBMIT"
                                type="submit"
                            />
                        </form>
                    <div className="Login-user-redirect-message">
                        If you are not an adminstrator for your organisation, please log in <a href={USER_CONSOLE_URL}>here</a>.
                    </div>
                    </div>
                </div>
            </div>
        );
    }

}

export default Login;
