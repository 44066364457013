import React from 'react';
import PropTypes from 'prop-types';

const StatBlock = (props) => {
    const { tag, title, value, value_note, change } = props;
    return (
        <div className="ibox float-e-margins">
            <div className="ibox-title">
                <span className="label label-success pull-right">{tag}</span>
                <h5>{title}</h5>
            </div>
            <div className="ibox-content">
                <h1 className="no-margins">{value}</h1>
                <div className="stat-percent font-bold text-success">{change} <i className="fa fa-bolt"></i></div>
                <small>{value_note}</small>
            </div>
        </div>
    )
}

StatBlock.propTypes = {
    tag: PropTypes.string,
    title: PropTypes.string,
    value: PropTypes.number,
    change: PropTypes.number,
    value_note: PropTypes.string
}

export default StatBlock;
