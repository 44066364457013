import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Navigation extends Component {

    constructor(props){
        super();

        const { auth } = props;
        const user_profile = auth.getProfile();

        this.state = {
            user: user_profile
        };

    }

    activeRoute(routeName) {
        if (routeName === "/")
            return this.props.history.location.pathname === routeName ? "active" : "";

        return this.props.history.location.pathname.includes(routeName) ? "active" : "";
    }

    secondLevelActive(routeName) {
        return this.props.history.location.pathname === routeName ? "nav nav-second-level collapse in" : "nav nav-second-level collapse";
    }

    render() {

        const { user } = this.state;
        const { routes } = this.props;

        return (
            <nav className="navbar-default navbar-static-side" >
                    <ul className="nav metismenu collapse in" id="side-menu" ref="menu">
                        <li className="nav-header">
                            <div className="dropdown profile-element"> <span>
                             </span>
                            <a>
                            <span className="clear"> <span className="block m-t-xs"> <strong className="font-bold">{user.name}</strong>
                             </span> <span className="text-muted text-xs block">Administrator</span> </span> </a>
                            </div>
                            <div className="logo-element">
                                IN+
                            </div>
                        </li>
                        {routes.map((route, index) => (
                             route.menu &&
                                <li className={this.activeRoute(route.path)} key={index}>
                                    <Link to={route.path}><i className="fa fa-th-large"></i> <span className="nav-label">{route.name}</span></Link>
                                </li>

                        ))};
                    </ul>

            </nav>
        )
    }
}

export default Navigation
