import React from 'react';

import Loading from '../../common/Loading';

import './Form.css';

const Form = (props) => {
    const { title, desc, form_data, loading } = props;

    return (
        <div className="wrapper wrapper-content animated fadeIn">
            <div className="row">
                <div className="col-lg-6 col-lg-offset-3">
                    <div className="ibox float-e-margins">
                        <div className="ibox-title">
                            <h5>{ title }</h5>
                        </div>
                        <div className="ibox-content">
                            <form className="form-horizontal">
                                <p>{ desc }</p>
                                { form_data && form_data.inputs.map((input, index) => (
                                    <div className="form-group" key={index}>
                                        <label className="col-lg-2 control-label">{input.name}</label>
                                        <div className="col-lg-10">
                                            {input.render(input)}
                                            <span className="help-block m-b-none">{input.label}</span>
                                        </div>
                                    </div>
                                ))}
                                <div className="form-group">
                                    <div className="col-lg-offset-2 col-lg-10">
                                        { loading && (
                                            <div>
                                                <button className="btn btn-sm btn-success" type="submit" disabled>{form_data.submit.label}</button>&nbsp;
                                                { form_data && form_data.delete &&
                                                    <button className="btn btn-sm btn-danger" disabled>{form_data.delete.label}</button>
                                                }
                                                <span className="Form-Loading"><Loading width={"20px"} height={"20px"} /></span>
                                            </div>
                                        )}
                                        { !loading && form_data && (
                                            <div>
                                                <button className="btn btn-sm btn-success" type="submit" onClick={form_data.submit.callback}>{form_data.submit.label}</button>&nbsp;
                                                { form_data && form_data.delete &&
                                                        <span>
                                                            <button type="button" className="btn btn-sm btn-danger" data-toggle="modal" data-target="#deleteitem">{form_data.delete.label}</button>
                                                            <div className="modal inmodal" id="deleteitem" tabIndex="-1" role="dialog"  aria-hidden="true">
                                                                <div className="modal-dialog">
                                                                    <div className="modal-content animated fadeIn">
                                                                        <div className="modal-header">
                                                                            <button type="button" className="close" data-dismiss="modal"><span aria-hidden="true">&times;</span><span className="sr-only">Close</span></button>
                                                                            <i className="fa fa-trash-o modal-icon"></i>
                                                                            <h4 className="modal-title">Deletion Confirmation</h4>
                                                                            <small>Are you sure you'd like to delete this item?</small>
                                                                        </div>
                                                                        <div className="modal-footer">
                                                                            <button type="button" className="btn btn-white" data-dismiss="modal">Close</button>
                                                                            <button type="button" className="btn btn-danger Form-Delete" data-dismiss="modal" onClick={form_data.delete.callback}>{form_data.delete.label}</button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </span>

                                                }
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}

export default Form;
