import React from 'react';
import Moment from 'react-moment';
import FontAwesome from 'react-fontawesome';

import { API_URL, ALERT_LABELS } from '../../config';
import Loading from '../common/Loading';
import Table from '../inspinia/components/Table';
import AlertButtonGroup from './AlertButtonGroup';

import './AlertBox.css';

/**
 * This component is a self contained widget for showing alerts for an organisation
 */
class AlertBox extends React.Component {

    constructor() {
        super();

        this.state = {
            // Data related
            valve_loading: false,
            valve_data: [],
            gateway_loading: false,
            gateway_data: [],
            info: false,

            // Pagination related
            valve_offset: 0,
            valve_count: 0,
            valve_limit: 10,
            gateway_offset: 0,
            gateway_count: 0,
            gateway_limit: 5,

            // Dismiss related
            dismiss_loading: false,
        };


        this.loadData = this.loadData.bind(this);
        this.pageFunction = this.pageFunction.bind(this);
        this.gatewayPageFunction = this.gatewayPageFunction.bind(this);
        this.valvePageFunction = this.valvePageFunction.bind(this);
        this.dismissAlert = this.dismissAlert.bind(this);
    }

    componentDidMount(){
        this.loadData();
    }

    loadData(){
        const { auth } = this.props;
        const { valve_offset, valve_limit, gateway_offset, gateway_limit } = this.state;

        this.setState({valve_loading: true, gateway_loading: true});

        auth.fetch(`${API_URL}/alerts/?name__ne=stale_gateway&limit=${valve_limit}&offset=${valve_offset}`)
            .then((response)=>{
                this.setState({
                    valve_loading: false,
                    valve_data: response.results,
                    valve_count: response.count,
                });
            })
            .catch((error) => {
                this.setState({
                    valve_loading: false,
                    error: error.message,
                });
            });

        auth.fetch(`${API_URL}/alerts/?name=stale_gateway&limit=${gateway_limit}&offset=${gateway_offset}`)
            .then((response)=>{
                this.setState({
                    gateway_loading: false,
                    gateway_data: response.results,
                    gateway_count: response.count,
                });
            })
            .catch((error) => {
                this.setState({
                    gateway_loading: false,
                    error: error.message,
                });
            });
    }

    // Higher Order Function to generate page functions
    pageFunction(dir, count_name, limit_name, offset_name){
        const count = this.state[count_name];
        const limit = this.state[limit_name];
        const offset = this.state[offset_name];
        let newOffset = offset;

        if (dir === "next"){
            if (offset < count){
                newOffset += limit;
            }
        } else if (dir === "prev"){
            if (offset > 0){
                newOffset = Math.max(0, offset - limit);
            }
        }

        if (newOffset !== offset){
            this.setState({[offset_name]: newOffset}, () => {
                this.loadData();
            });
        }
    }

    gatewayPageFunction(dir){
        this.pageFunction(dir, "gateway_count", "gateway_limit", "gateway_offset");
    }

    valvePageFunction(dir){
        this.pageFunction(dir, "valve_count", "valve_limit", "valve_offset");
    }

    dismissAlert(alert_id){
        const { auth } = this.props;

        const headers = {
            "Accept": "application/json",
            "Content-Type": "application/json",
        }

        auth.fetch_raw(`${API_URL}/alerts/${alert_id}/dismiss/`, {
            method: "GET",
            ...headers,
        })
        .then(auth._checkStatus)
        .then((response) => {
            this.loadData();
        })
        .catch((error) => {
            this.setState({
                error: error.errorMessage,
            });
        });
    }

    render() {
        const { auth, cache } = this.props;
        const { gateway_data, valve_data, gateway_loading, valve_loading, valve_count, valve_limit, valve_offset} = this.state;
        const { gateway_count, gateway_limit, gateway_offset, assign_loading, info } = this.state;
        const { valvePageFunction, gatewayPageFunction, dismissAlert } = this;

        let gatewayTotalPages = Math.ceil(gateway_count / gateway_limit);
        let gatewayPage = (gateway_offset / gateway_limit) + 1;

        let valveTotalPages = Math.ceil(valve_count / valve_limit);
        let valvePage = (valve_offset / valve_limit) + 1;

        // Table configuration, has to be different for gateways and valves as gateways do not have a room
        let gateway_data_rows = [
            {
                label: "Name",
                key: "object_id",
                transform: (id) => {return cache.get("gateways", id, this, "name") }
            },
            {
                label: "Alert Type",
                key: "name",
                transform: (value) => {return ALERT_LABELS[value]}
            },
            {
                label: "Last Seen",
                key: "last_seen",
                transform: (value) => {return <Moment date={value} format={"YYYY/MM/DD HH:mm"} />}
            },
        ];

        let valve_data_rows = [
            {
                label: "Name",
                key: "object_id",
                transform: (id) => {return cache.get("valves", id, this, "name") }
            },
            {
                label: "Room",
                key: "room",
                transform: (id) => {return cache.get("rooms", id, this, "name") }
            },
            {
                label: "Alert Type",
                key: "name",
                transform: (value) => {return ALERT_LABELS[value]}
            },
            {
                label: "Last Seen",
                key: "last_seen",
                transform: (value) => {return <Moment date={value} format={"YYYY/MM/DD HH:mm"} />}
            },
        ];

        return (
            <div className="AlertBox-container ibox float-e-margins">
                <div className="ibox-title AlertBox-title">
                    <h5 className="AlertBox-h5"><FontAwesome name={"warning"}/> Current Alerts</h5>
                    <button className="btn btn-sm btn-default pull-right" onClick={() => this.setState({info: true})} >Alert Info</button>
                </div>
                <div className="ibox-content">
                    { gateway_data.length > 0 && (
                            <Table
                                    title={"Gateway Alerts"} data={gateway_data} data_rows={gateway_data_rows}
                                    edit={false} detail={false} loading={gateway_loading}
                                    page={gatewayPage} totalPages={gatewayTotalPages} pageFunction={gatewayPageFunction} top_paginate={false}
                                    custom_actions={
                                        [
                                            (alert_data) => {
                                              return (
                                                  <td key={`${alert_data.id}-custom`}>
                                                      <button
                                                        className="btn btn-sm btn-info AlertBox-dismiss-btn"
                                                        onClick={() => dismissAlert(alert_data.id)}>Dismiss Alert</button>
                                                  </td>
                                              )
                                            }
                                        ]
                                    }
                            />)
                    }
                    {valve_data.length > 0 && (
                            <Table title={"Valve Alerts"} data={valve_data}
                                    data_rows={valve_data_rows}
                                    edit={false} detail={false} loading={valve_loading}
                                    page={valvePage} totalPages={valveTotalPages} pageFunction={valvePageFunction} top_paginate={false}
                                    custom_actions={
                                        [
                                            (alert_data) => {
                                              return (
                                                  <td key={`${alert_data.id}-custom`}>
                                                      <div>
                                                          <button
                                                            className="btn btn-sm btn-info AlertBox-dismiss-btn"
                                                            onClick={() => dismissAlert(alert_data.id)}>Dismiss Alert</button>
                                                        <div className="AlertBox-HSpacer"></div>
                                                        <AlertButtonGroup alertName={alert_data.name} auth={auth} valve={alert_data.object_id} />
                                                      </div>
                                                  </td>
                                              );
                                            }
                                        ]
                                    }
                            />
                        )
                    }
                    {!gateway_loading && !valve_loading && gateway_data.length == 0 && valve_data.length == 0 &&
                            <div className="well well-lg text-center">
                                <h3>
                                    No Alerts
                                </h3>
                                Great, all systems are working.
                            </div>
                    }
                </div>
                { info &&
                    <div className="AlertBox-overlay animated fadeIn">
                        <div className="row">
                            <div className="col-md-10 col-md-offset-1">
                                <div className="ibox float-e-margins animated fadeInUp">
                                    <div className="ibox-title">
                                        <h5>Alert Information</h5>
                                        <div className="ibox-tools">
                                            <FontAwesome name="times" onClick={() => this.setState({info: false})} />
                                        </div>
                                    </div>
                                    <div className="ibox-content">
                                        <div className="row">
                                            <div className="col-md-12 horizontal-center">
                                                <table className="footable table table-stripped">
                                                    <thead>
                                                        <th>Name</th>
                                                        <th>Description</th>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td><strong>Stale Gateway Comms.</strong></td>
                                                            <td><p>This alert is triggered when a gateway has not communicated with the server in more than 10 minutes. As the regular communication interval is 1 min, this indicates that the gateway may have unplugged or will need a manual reset.</p></td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong>Stale Valve Comms.</strong></td>
                                                            <td><p>This alert is triggered when a valve has not communicated with the server in more than 1 hour. As the regular communication interval is 10 min, this indicates that something is blocking the radio signal or that the valve has malfunctioned and needs manual intervention.</p></td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong>Unexplained Temp. Increase</strong></td>
                                                            <td><p>This alert is triggered when a valve is recording an increase in temperature but is fully closed. This may indicate that there is a nother heat source close by, high solar flux, or that the valve head has either been removed or misseated.</p></td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong>Temperature Unchanged</strong></td>
                                                            <td><p>This alert is triggered when a valve reports the same temperature for more than 1 hour. This could be a valve that needs reset or a remarkably stable thermal environment.</p></td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong>Failed Teach-in</strong></td>
                                                            <td><p>This alert is triggered when a valve opens up when it is reporting a temperature below 21C (the default) when the room setpoint is a different value. This indiciates that the valve is not receiving commands from the correct gateway (or the teach-in procedure has failed).</p></td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong>Low Battery</strong></td>
                                                            <td><p>This alert is triggered when a valve has a low battery status for over 24 hours. Ideally this room should be heated to charge the valve, or the valve put into summer mode.</p></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        );
    }
}

export default AlertBox;

