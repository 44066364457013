import React from 'react';
import AuthService from '../services/AuthService';
import {API_URL} from '../../config';

function withAuth(AuthComponent){
    const Auth = new AuthService(API_URL);

    return class AuthWrapped extends React.Component {

        constructor(){
            super();
            this.state = {
                user: null
            };
        }

        componentWillMount(){
            if (!Auth.loggedIn()){
                this.props.history.replace('/login');
            } else {
                try {
                    const user = Auth.getProfile();
                    if (user.is_org_admin) {
                        this.setState({
                            user
                        });
                    } else {
                        throw new Error("User not an admin");
                    }
                } catch (err) {
                    Auth.logout();
                    this.props.history.replace('/login');
                }
            }
        }

        render(){
            const { user } = this.state;

            if (user) {
                return (
                    <AuthComponent history={this.props.history} user={user} auth={Auth} />
                )
            } else {
                return null;
            }
        }

    }
}

export default withAuth;
