import React from 'react';

import { API_URL } from '../../config';

import Loading from '../common/Loading';


/**
 * This service is used to cache object data from various REST calls.
 * This can then be used in data transform functions to avoid unneeded additional calls to the REST api.
 *
 * Usage:
 *
 *
 * // In components
 * floor_obj = {id: 1, name: "Ground", building: 47}
 *
 * // This will perform a GET request if the application hasn't already
 * building = cache.get("buildings", id, this)
 * // This will use cached data
 * building_name = cache.get("buildings, id, this, "name")
 *
 *
 *  // As part of the app conf
 *  view_props: {
 *      ...
 *      data_rows: [
 *         ... ,
 *         {
 *             label: "Gateway",
 *             key: "gateway",
 *             tranform: (id) => {return cache.get("gateways", id, this, "name") }
 *         },
 *     ],
 *     ...
 * }
 */
class CacheService {

    constructor(auth) {
        this.cache = {};
        this.auth = auth;

        this.get = this.get.bind(this)
        this.set = this.set.bind(this)
        this.set_multi = this.set_multi.bind(this)
        this.preload = this.preload.bind(this)
    }

    preload(obj) {
        this.auth.fetch(`${API_URL}/${obj}/`)
            .then((response)=>{
                this.set_multi(obj, "id", response.results);
            })
    }

    load(obj, id, component) {

        if (id instanceof Loading)
            return;

        const state_var = `_${obj}_${id}_loading`;

        if (state_var in component.state)
            return; // Only fetch one at a time

        component.setState({[state_var]: true});
        this.auth.fetch(`${API_URL}/${obj}/${id}`)
            .then((response)=>{
                this.set(obj, "id", response);
                component.setState({[state_var]: false});
            })
            .catch((error)=>{
                component.setState({[state_var]: false});
            })
    }

    get(obj, id, component, field=null){
        if (obj in this.cache) {
            if (id in this.cache[obj]){
                let retreived_obj = this.cache[obj][id];
                return field ? retreived_obj[field] : retreived_obj;
            }
        }

        this.load(obj, id, component);
        return <Loading width={"10px"} height={"10px"} />
    }

    set(obj, id, data){
        if (obj in this.cache === false)
            this.cache[obj] = {};
        this.cache[obj][id] = data;
    }

    set_multi(obj, id_key, data_list){
        data_list.map((data) => {
            this.set(obj, data[id_key], data);
        });
    }

}

export default CacheService;
