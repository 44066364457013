import React, { Component } from 'react';
import {API_URL} from '../../config';

import Loading from '../common/Loading';
import StatBlock from '../inspinia/components/StatBlock';

import AlertBox from '../alerts/AlertBox';
import UnassignedValves from '../unassigned/UnassignedValves';

class DashboardView extends Component {

    constructor(props){
        super(props);

        this.state = {
            data:  {},
            error: null,
            loading: true,
        };

        this.loadData = this.loadData.bind(this);
    }

    componentDidMount(){
        this.loadData();
    }

    loadData(){
        const { auth, endpoint } = this.props;

        this.setState({loading: true});

        auth.fetch(`${API_URL}/${endpoint}`)
            .then((response)=>{
                this.setState({
                    loading: false,
                    data: response
                });
            })
            .catch((error) => {
                this.setState({
                    loading: false,
                    error: error.message,
                });
            });
    }


    render() {

        const { loading, data } = this.state;
        const { total_valves, total_active_valves, total_heating_valves, total_actions } = data;
        const { auth, cache } = this.props;

        return (
            <div className="wrapper wrapper-content animated fadeIn">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="text-center m-t-lg">
                            <h1>
                                Aeriform Dashboard
                            </h1>
                            <small>
                                Your organisation at a glance.
                            </small>
                        </div>
                    </div>
                </div>
                <br/>
                { loading ? (
                    <div className="row">
                        <br />
                        <div className="col-lg-4 col-lg-offset-5">
                            <Loading width={"100px"} height={"100px"} />
                        </div>
                    </div>
                ) : (
                    <div className="row">
                        <div className="col-lg-3">
                                { total_valves &&
                                    <StatBlock
                                        tag={"Hourly"} title={"Total Valves"}
                                        value={total_valves.value} value_note={"Valves in system"}
                                        change={total_valves.change} />
                                }
                        </div>
                        <div className="col-lg-3">
                            { total_active_valves &&
                                <StatBlock
                                    tag={"Hourly"} title={"Active Valves"}
                                    value={total_active_valves.value} value_note={"Valves active in last hour"}
                                    change={total_active_valves.change} />
                            }
                        </div>
                        <div className="col-lg-3">
                            { total_heating_valves &&
                                <StatBlock
                                    tag={"Hourly"} title={"Heating Valves"}
                                    value={total_heating_valves.value} value_note={"Valves currently open in last hour"}
                                    change={total_heating_valves.change} />
                            }
                        </div>
                        <div className="col-lg-3">
                            { total_actions &&
                                <StatBlock
                                    tag={"Hourly"} title={"Actions"}
                                    value={total_actions.value} value_note={"Actions in last hour"}
                                    change={total_actions.change} />
                            }
                        </div>
                    </div>
                    )
                }
                <br />
                <div className="row">
                    <div className="col-lg-12">
                        <AlertBox auth={auth} cache={cache} />
                    </div>
                </div>
                <br />
                <div className="row">
                    <div className="col-lg-12">
                        <UnassignedValves auth={auth} cache={cache} />
                    </div>
                </div>
            </div>
        )
    }

}

export default DashboardView;
