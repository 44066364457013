import React from 'react';
import PropTypes from 'prop-types';

import { RESET_BUTTON_PROPS, REFERENCERUN_BUTTON_PROPS, TEACHIN_BUTTON_PROPS } from '../../config';
import WorkflowButton from '../buttons/WorkflowButton';


const AlertButtonGroup = (props) => {
    const { alertName, valve, auth } = props;
    return (
      <div className="btn-group">
        {<WorkflowButton auth={auth} valve={valve} {...REFERENCERUN_BUTTON_PROPS} />}
        {alertName == "wrong_setpoint_valve" && <WorkflowButton auth={auth} valve={valve} {...TEACHIN_BUTTON_PROPS} />}
      </div>
    )
}

AlertButtonGroup.propTypes = {
    alertName: PropTypes.string,
    valve: PropTypes.number,
    auth: PropTypes.object,
}

export default AlertButtonGroup;

