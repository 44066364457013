import { API_URL } from '../../config';

import AddView from './AddView';

class UpdateView extends AddView {

    constructor(props){
        super(props);

        this.method = "PUT"

        this.getObjectData = this.getObjectData.bind(this);
        this.deleteObject = this.deleteObject.bind(this);
        this.buildFormData = this.buildFormData.bind(this);

    }

    componentWillMount(){
        const { id } = this.props.match.params;
        this.getObjectData(id);
    }

    componentWillReceiveProps(newProps){
        if (this.props.location.pathname !== newProps.location.pathname) {
            const { id } = newProps.match.params;
            this.getObjectData(id);
        }
    }

    getObjectData(id){
        const { auth, endpoint } = this.props;

        this.setState({loading: true});

        // Get object data, set state
        auth.fetch(`${API_URL}/${endpoint}${id}/`)
        .then((response) => {
            this.setState(response);
            this.buildFormData();
        })
        .catch((error) => {
            this.setState({
                error: error.message,
                loading: false
            });
        });
    }

    deleteObject(id){
        const { auth, endpoint, redirect, history } = this.props;

        this.setState({loading: true});

        auth.fetch_raw(`${API_URL}/${endpoint}${id}/`, {method: "DELETE"})
        .then((response) => {
            if (response.status === 204){
                this.setState({loading: false});
                history.push(redirect);
            } else {
                var error = new Error(response.statusText);
                error.response = response;
                throw error;
            }
        })
        .catch((error) => {
            this.setState({
                error: error.message,
                loading: false
            });
        });
    }


    buildFormData(){

        const { form_config } = this.props;

        let change = this.changeFunc;
        let render = {
            inputText: this.renderInputText,
            select: this.renderSelect,
            inputNumber: this.renderInputNumber
        }
        let { state } = this;

        let form_data = {
            inputs: form_config.map(
                (input) => {
                    return Object.assign(
                        {
                            change,
                            render: render[input.type],
                            value: state[input.api_name],
                        }, input)
                }),
            submit: {
                label: "UPDATE",
                callback: (event) => {
                    let data = {};
                    form_config.forEach((conf) => {
                        data[conf.api_name] = this.state[conf.api_name];
                    });
                    this.submitData(event, data );
                }
            },
            delete: {
                label: "DELETE",
                callback: (event) => {
                    const { id } = this.props.match.params;
                    this.deleteObject(id);
                }
            }
        };
        this.setState({ form_data, loading: false});

    }

}

export default UpdateView;
