// Import Raven and configure it's version number
import Raven from 'raven-js';
import { version } from '../package.json';

// Set up sentry for this application.
function configSentry() {
  return Raven.config('https://bb1791ec19fb40849ada2713483f6ed2@sentry.heinventions.com/28', {
    release: version,
    environment: process.env.NODE_ENV,
  }).install();
}

export default configSentry();