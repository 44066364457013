import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';

import Moment from 'react-moment';

import withAuth from './components/ho/withAuth';
import CacheService from './components/services/CacheService';

import Navigation from './components/inspinia/components/Navigation';
import Footer from './components/inspinia/components/Footer';
import TopHeader from './components/inspinia/components/TopHeader';

import { FORM_CONFIG } from './config';

import DashboardView from './components/views/DashboardView';

import ListView from './components/views/ListView';
import AddView from './components/views/AddView';
import UpdateView from './components/views/UpdateView';
import ValveDetailView from './components/views/ValveDetailView';

import './App.css';
import './components/inspinia/inspinia.css';


class App extends Component {

    constructor(props){
        super(props);

        this.state = {
            auth: props.auth,
            user: props.user,
        };

        this.handleLogout = this.handleLogout.bind(this);
        this.getRoutes = this.getRoutes.bind(this);

        this.cache = new CacheService(props.auth);
    }

    componentDidMount(){
        this.cache.preload("buildings");
        this.cache.preload("floors");
        this.cache.preload("rooms");
        this.cache.preload("valves");
        this.cache.preload("gateways");
    }

    getRoutes(){
        return [
            {
                path: "/",
                exact: true,
                name: "Dashboard",
                main: DashboardView,
                menu: true,
                view_props: {
                    endpoint: "dashboard",
                },
            },
            {
                path: "/buildings",
                exact: true,
                name: "Buildings",
                main: ListView,
                view_props: {
                    data_rows: [
                        {
                            label: "Name",
                            key: "name",
                        }
                    ],
                    endpoint: "buildings",
                    title: "Buildings",
                    addButtonRoute: "/buildings/new",
                    edit: true,
                },
                menu: true,
            },
            {
                path: "/floors",
                exact: true,
                name: "Floors",
                main: ListView,
                view_props: {
                    data_rows: [
                        {
                            label: "Building",
                            key: "building",
                            transform: (id) => {return this.cache.get("buildings", id, this, "name") }
                        },
                        {
                            label: "Name",
                            key: "name",
                        },
                    ],
                    endpoint: "floors",
                    title: "Floors",
                    addButtonRoute: "/floors/new",
                    edit: true,
                },
                menu: true,
            },
            {
                path: "/rooms",
                exact: true,
                name: "Rooms",
                main: ListView,
                view_props: {
                    data_rows: [
                        {
                            label: "Floor",
                            key: "floor",
                            transform: (id) => {return this.cache.get("floors", id, this, "name") }
                        },
                        {
                            label: "Name",
                            key: "name",
                        },
                        {
                            label: "Set Point",
                            key: "set_point",
                        },
                        {
                            label: "Temperature",
                            key: "temperature",
                            transform: (value) => {return value ? value.toFixed(2) : "--"}
                        },
                    ],
                    endpoint: "rooms",
                    title: "Rooms",
                    addButtonRoute: "/rooms/new",
                    edit: true,
                },
                menu: true,
            },
            {
                path: "/valves",
                exact: true,
                name: "Valves",
                main: ListView,
                view_props: {
                    data_rows: [
                        {
                            label: "Room",
                            key: "room",
                            transform: (id) => {return this.cache.get("rooms", id, this, "name") }
                        },
                        {
                            label: "Gateway",
                            key: "gateway",
                            transform: (id) => {return this.cache.get("gateways", id, this, "name") }
                        },
                        {
                            label: "Name",
                            key: "name",
                        },
                        {
                            label: "Set Point",
                            key: "set_point",
                        },
                        {
                            label: "Temperature",
                            key: "temperature",
                            transform: (value) => {return value ? value.toFixed(2) : "--"}
                        },
                        {
                            label: "Last Updated",
                            key: "last_updated",
                            transform: (value) => {return <Moment date={value} format={"YYYY/MM/DD HH:mm"} />}
                        },
                    ],
                    endpoint: "valves",
                    title: "Valves",
                    detail: true,
                    detail_attr: "name",
                    filters: {room__isnull: false}
                },
                menu: true,
            },
            {
                path: "/gateways",
                exact: true,
                name: "Gateways",
                main: ListView,
                view_props: {
                    data_rows: [
                        {
                            label: "Name",
                            key: "name",
                        },
                        {
                            label: "IP Address",
                            key: "ip_address",
                        },
                        {
                            label: "Last Updated",
                            key: "last_communication",
                            transform: (value) => {return <Moment date={value} format={"YYYY/MM/DD HH:mm"} />}
                        },
                        {
                            label: "Room",
                            key: "room",
                            transform: (id) => {return this.cache.get("rooms", id, this, "name") }
                        },
                    ],
                    endpoint: "gateways",
                    title: "Gateways",
                    no_action: true,
                },
                menu: true,
            },
            {
                path: "/buildings/new",
                exact: true,
                name: "Add Building",
                main: AddView,
                view_props: {
                    form_config: FORM_CONFIG.building,
                    endpoint: "buildings/",
                    redirect: "/buildings",
                    listTitle: "Buildings",
                    desc: "Add a new building to your organisation",
                },
                menu: false,
            },
            {
                path: "/floors/new",
                exact: true,
                name: "Add Floor",
                main: AddView,
                view_props: {
                    form_config: FORM_CONFIG.floor,
                    endpoint: "floors/",
                    redirect: "/floors",
                    listTitle: "Floors",
                    desc: "Add a new floor to your organisation",
                },
                menu: false,
            },
            {
                path: "/rooms/new",
                exact: true,
                name: "Add Room",
                main: AddView,
                view_props: {
                    form_config: FORM_CONFIG.room,
                    endpoint: "rooms/",
                    redirect: "/rooms",
                    listTitle: "Rooms",
                    desc: "Add a new room to your organisation",
                },
                menu: false,
            },
            {
                path: "/buildings/:id",
                exact: true,
                name: "Update Building",
                main: UpdateView,
                view_props: {
                    form_config: FORM_CONFIG.building,
                    endpoint: "buildings/",
                    redirect: "/buildings",
                    listTitle: "Buildings",
                    desc: "Update a building in your organisation",
                },
                menu: false,
            },
            {
                path: "/floors/:id",
                exact: true,
                name: "Update Floor",
                main: UpdateView,
                view_props: {
                    form_config: FORM_CONFIG.floor,
                    endpoint: "floors/",
                    redirect: "/floors",
                    listTitle: "Floors",
                    desc: "Update a floor in your organisation",
                },
                menu: false,
            },
            {
                path: "/rooms/:id",
                exact: true,
                name: "Update Room",
                main: UpdateView,
                view_props: {
                    form_config: FORM_CONFIG.room,
                    endpoint: "rooms/",
                    redirect: "/rooms",
                    listTitle: "Rooms",
                    desc: "Update a Room in your organisation",
                },
                menu: false,
            },
            {
                path: "/valves/:name",
                exact: true,
                name: "Valve Summary",
                main: ValveDetailView,
                view_props: {
                    endpoint: "valves/",
                    redirect: "/valves",
                    title: "Valve Details",
                    detailTitle: "Valves",
                    desc: "Get details on a valve in your organisation",
                    form_config: FORM_CONFIG.action,
                    data_rows: [
                        {
                            label: "Datetime",
                            key: "datetime",
                            transform: (value) => {return <Moment date={value} format={"YYYY/MM/DD HH:mm"} />}
                        },
                        {
                            label: "Gateway",
                            key: "gateway",
                            transform: (id) => {return this.cache.get("gateways", id, this, "name") }
                        },
                        {
                            label: "Temperature",
                            key: "temperature",
                            transform: (value) => {return value.toFixed(2)}
                        },
                        {
                            label: "Open State",
                            key: "state",
                            transform: (value) => {return `${value} %`}
                        },
                        {
                            label: "Battery Low",
                            key: "is_battery_low",
                            transform: (value) => {return value.toString()}
                        },
                        {
                            label: "Charging",
                            key: "is_charging",
                            transform: (value) => {return value.toString()}
                        },
                    ],
                },
                menu: false,
            },
        ]
    }

    handleLogout(){
        const {auth} = this.state;

        auth.logout();
        this.props.history.replace('/login');
    }


    render() {

        const { auth } = this.props;
        const { cache } = this;

        let wrapperClass = "gray-bg"

        return (
            <div id="wrapper">
                <Navigation history={this.props.history} auth={auth} routes={this.getRoutes()} />

                <div id="page-wrapper" className={wrapperClass}>

                    <TopHeader handleLogout={this.handleLogout} routes={this.getRoutes()}/>

                    <Switch>
                        {this.getRoutes().map((route, index) => (
                            <Route
                                key={index}
                                path={route.path}
                                render={(props) => <route.main {...props} {...route.view_props} auth={auth} cache={cache} />}
                                exact={route.exact}
                            />
                        ))}
                    </Switch>

                    <Footer />

                </div>

            </div>
        );
    }
}

export default withAuth(App);
