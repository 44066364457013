import React from 'react';

export const renderInputText = (input_data) => {
    const { placeholder, api_name, change, value} = input_data;
    if (value)
        return (
            <input type="text" placeholder={placeholder}
                name={api_name} className="form-control" onChange={change} value={value}/>
            )
    return (
        <input type="text" placeholder={placeholder}
            name={api_name} className="form-control" onChange={change}/>
        )
};

export const renderInputNumber = (input_data) => {
    const { placeholder, api_name, change, min, max, value } = input_data;
    return (
        <input type="number" placeholder={placeholder} name={api_name}
            className="form-control" onChange={change} min={min} max={max} value={value}/>
        )
};

