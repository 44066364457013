import React from 'react';
import moment from 'moment';

import { API_URL } from '../../config';
import Loading from '../common/Loading';

import PropTypes from 'prop-types';

import './WorkflowButton.css';

/**
 * This component is a self contained button for sending a WorkflowRequest
 */
class WorkflowButton extends React.Component {

    constructor(props) {
        super();

        this.state = {
            loading: false,
        };

        this.workflow = props.workflow;
        this.label = props.label;

        this.sendWorkflowRequest = this.sendWorkflowRequest.bind(this);
    }

    sendWorkflowRequest(){
        const { workflow } = this;
        const { auth, valve } = this.props;

        const headers = {
            "Accept": "application/json",
            "Content-Type": "application/json",
        }

        this.setState({loading: true});

        auth.fetch(`${API_URL}/workflowrequests/`, {
            method: "POST",
            body: JSON.stringify({
                target: valve,
                scheduled_time: moment(Date.now()).format(),
                return_data: "false",
                workflow,
            }),
            ...headers,
        })
        .then((response) => {
            this.setState({
                loading: false
            });
        })
        .catch((error) => {
            this.setState({
                error: error.errorMessage,
                loading: false
            });
        });
    }


    render() {
        const { label } = this;
        const { loading } = this.state;

        return (
            <div className="WorkflowButton-container">
                { loading && <span className="pull-right"><Loading width={"14px"} height={"14px"} /></span>}
                { !loading && <button className="btn btn-sm btn-danger WorkflowButton-btn" type="submit" onClick={this.sendWorkflowRequest}>{label}</button>}
            </div>
        );
    }
}

WorkflowButton.propTypes = {
    valve: PropTypes.number.isRequired,
    workflow: PropTypes.number.isRequired,
    label: PropTypes.string.isRequired,
    auth: PropTypes.object.isRequired,
};

export default WorkflowButton;
