import React from 'react';
import FontAwesome from 'react-fontawesome';

import Loading from '../common/Loading';
import {API_URL} from '../../config';

import "./RoomSearch.css";

/**
 * This is an autocomplete search widget for Rooms, and supports custom actions on click.
 */
class RoomSearch extends React.Component {

    constructor(props){
        super(props);

        const { auth } = props;

        this.state = {
            searchResults: [],
            searchQuery: '',
            loading: false,
            auth: auth,
            selected: true,
        }

        this.handleChange = this.handleChange.bind(this)
    }

    handleChange(event){
        const searchQuery = event.target.value;
        const { auth } = this.state;

        this.setState({ searchQuery });

        if (!searchQuery){
            return '';
        }

        this.setState({ loading: true });

        auth.fetch(`${API_URL}/rooms/?search=${searchQuery}`)
            .then((result) => {
                this.setState({
                    loading: false,
                    searchResults: result.results,
                    selected: false,
                });
            });
    }

    renderSearchResults(){

        const { searchResults, searchQuery, loading, selected } = this.state;
        const { action } = this.props

        if (!searchQuery){
            return '';
        }

        if (searchResults.length > 0 && !selected) {
            return (
                <div className="RoomSearch-result-container">
                    {searchResults.map(result =>(
                        <div
                            key={result.id}
                            className="RoomSearch-result"
                            onClick={() => {
                                this.setState({searchQuery: result.name, selected: true});
                                action(result.id);
                            }}
                        >
                            {result.name}
                        </div>
                    ))}
                </div>
            );
        }

        if (!loading && !selected){
            return (
                <div className="RoomSearch-result-container">
                    <div className="RoomSearch-no-result">
                        No results found.
                    </div>
                </div>
            );
        }

        return '';
    }

    render(){

        const { searchQuery, loading } = this.state;

        return (
            <div className="RoomSearch">
                <span className="RoomSearch-icon">
                    <FontAwesome name="search" />
                </span>
                <input
                    className="RoomSearch-input"
                    type="text"
                    placeholder="Room name"
                    onChange={this.handleChange}
                    value={searchQuery}
                />
                {loading &&
                        <div className="RoomSearch-loading">
                            <Loading width="12px" height="12px" />
                        </div>
                }
                {this.renderSearchResults()}
            </div>
        )
    }
}

export default RoomSearch;

