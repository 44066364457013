import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import FontAwesome from 'react-fontawesome';
import queryString from 'query-string';

import {API_URL} from '../../config';
import Table from '../inspinia/components/Table';

import './ListView.css';

class ListView extends Component {

    constructor(props){
        super(props);

        this.state = {
            data:  [],
            error: null,
            loading: false,
            offset: 0,
            count: 0,
            limit: 25,
        };

        this.loadData = this.loadData.bind(this);
        this.renderHeader = this.renderHeader.bind(this);
        this.pageFunction = this.pageFunction.bind(this);
    }

    componentDidMount(){
        this.loadData();
    }

    loadData(){
        const { auth, endpoint, cache } = this.props;
        const { offset, limit } = this.state;

        let { filters } = this.props;
        let page = { limit, offset};
        let urlparams = queryString.stringify({...page, ...filters});

        this.setState({loading: true});

        let url = `${API_URL}/${endpoint}/?${urlparams}`;

        auth.fetch(url)
            .then((response)=>{
                this.setState({
                    loading: false,
                    data: response.results,
                    count: response.count,
                });
                cache.set_multi(endpoint, "id", response.results);
            })
            .catch((error) => {
                this.setState({
                    loading: false,
                    error: error.message,
                });
            });
    }

    pageFunction(dir){
        const { count, limit, offset } = this.state;
        let newOffset = offset;

        if (dir === "next"){
            if (offset < count){
                newOffset += limit;
            }
        } else if (dir === "prev"){
            if (offset > 0){
                newOffset = Math.max(0, offset - limit);
            }
        }

        if (newOffset !== offset){
            this.setState({offset: newOffset}, () => {
                this.loadData();
            });
        }
    }

    renderHeader() {
        const { title, addButtonRoute } = this.props;
        return (
            <div className="row wrapper border-bottom white-bg page-heading">
                <div className="col-lg-10">
                    <h2>{title}</h2>
                    <ol className="breadcrumb">
                        <li><Link to="/">Home</Link></li>
                        <li className="active"><strong>{title}</strong></li>
                    </ol>
                </div>
                <div className="col-lg-2 ListView-ButtonBox">
                    { addButtonRoute &&
                        <Link to={addButtonRoute}>
                            <button className="btn btn-success" type="button">
                                <FontAwesome name="plus" />&nbsp;&nbsp;<span className="bold">Add</span>
                            </button>
                        </Link>
                    }
                </div>
            </div>
        );
    }

    render() {

        const { data, loading, count, limit, offset } = this.state;
        const { data_rows, endpoint, edit, detail, detail_attr, no_action } = this.props;

        let totalPages = Math.ceil(count / limit);
        let page = (offset / limit) + 1;

        return (
            <div>
                {this.renderHeader()}
                <div className="wrapper wrapper-content animated fadeIn">
                    <div className="row">
                        <div className="col-lg-12">
                            <Table
                                title={"List"} data={data} data_rows={data_rows}
                                edit_root={endpoint} edit={edit}
                                detail_root={endpoint} detail={detail} detail_attr={detail_attr}
                                no_action={no_action} loading={loading}
                                page={page} totalPages={totalPages} pageFunction={this.pageFunction}
                            />
                        </div>
                    </div>


                </div>
            </div>
        )
    }

}

export default ListView;
