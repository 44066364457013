import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import FontAwesome from 'react-fontawesome';

import {API_URL, READINGS_ENDPOINT, RESET_BUTTON_PROPS, REFERENCERUN_BUTTON_PROPS, TEACHIN_BUTTON_PROPS, CONFIGPIR_BUTTON_PROPS} from '../../config';
import Loading from '../common/Loading';
import Table from '../inspinia/components/Table';
import {renderInputNumber} from '../common/FormUtils';
import WorkflowButton from '../buttons/WorkflowButton';

import moment from 'moment';
import Moment from 'react-moment';
import HighCharts from 'react-highcharts';

import './ValveDetailView.css';

class ValveDetailView extends Component {

    constructor(props){
        super(props);

        let {form_config} = props;

        this.initial_chart_config = {
            chart: {
                zoomType: 'x'
            },
            series: [],
            tooltip: {
                style: {
                    padding: 10,
                    fontWeight: 'bold'
                },
                pointFormat: '{series.name}: <b>{point.y:.1f}</b><br/>',
                shared: true,
            },
            title: {text: ''},
            xAxis: {
                type: 'datetime',
            },
            yAxis: [
                {
                    title: {text: 'Temperature'},
                    labels: {format: '{value} °C'},
                    min: 0,
                    max: 30
                },
                {
                    title: {text: 'Valve Setpoint'},
                    labels: {format: '{value} %'},
                    opposite: true,
                    min: 0,
                    max: 100
                }
            ]
        };

        this.state = {
            data:  [],
            error: null,
            loading: false,
            form_data: null,
            set_point_message: "",
            readings_loading: true,
            room_loading: true,
            floor_loading: true,
            building_loading: true,
            gateway_loading: true,
            graph_loading: true,
            chart_config: this.initial_chart_config,
            count: 0,
            limit: 50,
            offset: 0,
        };

        this.loadData = this.loadData.bind(this);
        this.loadReadingsData = this.loadReadingsData.bind(this);
        this.loadActionData = this.loadActionData.bind(this);
        this.loadGraphData = this.loadGraphData.bind(this);
        this.loadRoomData = this.loadRoomData.bind(this);
        this.loadFloorData = this.loadFloorData.bind(this);
        this.loadBuildingData = this.loadBuildingData.bind(this);
        this.loadGatewayData = this.loadGatewayData.bind(this);
        this.loadGraphAndTable = this.loadGraphAndTable.bind(this);

        this.sendAction = this.sendAction.bind(this);
        this.clearSetPointMessage = this.clearSetPointMessage.bind(this);
        this.pageFunction = this.pageFunction.bind(this);

        this.renderHeader = this.renderHeader.bind(this);
        this.renderDetailsBlock = this.renderDetailsBlock.bind(this);
        this.renderLastReadingBlock = this.renderLastReadingBlock.bind(this);
        this.renderRequestActionFormBlock = this.renderRequestActionFormBlock.bind(this);

        this.changeFunc = (event) => {
            this.setState({[event.target.name]: event.target.value})
        };

        this.actionFormData = Object.assign({change: this.changeFunc}, form_config[0]);
        this.refreshTask = null;

    }

    componentDidMount(){
        this.loadData();
    }

    componentWillUnmount(){
        if (this.refreshTask)
            clearInterval(this.refreshTask);
    }

    loadData(){
        const { auth, endpoint } = this.props;
        const { name } = this.props.match.params;

        this.setState({loading: true});

        auth.fetch(`${API_URL}/${endpoint}?name=${name}`)
            .then((response)=>{
                if (response.results) {
                    this.setState({
                        loading: false,
                        data: response.results[0]
                    });

                    const {room} = response.results[0];

                    this.loadGraphAndTable(room);
                    this.loadRoomData(room)
                        .then((room) => {
                            if (room !== undefined)
                                return this.loadFloorData(room.floor)
                            return Promise.reject();
                        })
                        .then((floor) => { return this.loadBuildingData(floor.building) });
                    this.loadGatewayData(this.state.data.gateway);


                    // Start refresh timer every minute
                    this.refreshTask = setInterval(() => this.loadGraphAndTable(room), 60 * 1000);
                }
                this.setState({
                    loading: false,
                    error: "No Valve Found",
                });
            })
            .catch((error) => {
                this.setState({
                    loading: false,
                    error: error.message,
                });
            });
    }

    loadGraphAndTable(room){
        const { data } = this.state;
        const { id } = data;

        this.loadReadingsData(id, "readings_loading", "readings_data");
        this.loadReadingsData(id, "graph_loading", "graph_data", 700)
            .then(() => {return this.loadActionData(room, "graph_action_loading", "graph_action_data", 700)})
            .then(() => {this.loadGraphData()});

    }

    loadAPIData(api_name, id, loading_key, data_key){
        const { auth } = this.props;

        this.setState({[loading_key]: true});

        return auth.fetch(`${API_URL}/${api_name}/${id}`)
            .then((response) => {
                this.setState({
                    [loading_key]: false,
                    [data_key]: response
                });
                return response;
            })
            .catch((error) => {
                this.setState({
                    [loading_key]: false,
                    error: error.message,
                });
            })
    }

    loadGatewayData(gateway_id){
        return this.loadAPIData(
            "gateways", gateway_id, "gateway_loading", "gateway_data"
        );
    }

    loadRoomData(room_id){
        return this.loadAPIData(
            "rooms", room_id, "room_loading", "room_data"
        );
    }

    loadFloorData(floor_id){
        return this.loadAPIData(
            "floors", floor_id, "floor_loading", "floor_data"
        );
    }

    loadBuildingData(building_id){
        return this.loadAPIData(
            "buildings", building_id, "building_loading", "building_data"
        );
    }

    loadReadingsData(valve_id, loading_key, data_key, limit=50, offset=0){
        const { auth } = this.props;

        this.setState({[loading_key]: true});

        return auth.fetch(`${API_URL}/${READINGS_ENDPOINT}?ordering=-datetime&valve=${valve_id}&limit=${limit}&offset=${offset}`)
            .then((response)=>{
                this.setState({
                    [loading_key]: false,
                    [data_key]: response.results,
                    count: response.count,
                });
            })
            .catch((error) => {
                this.setState({
                    [loading_key]: false,
                    error: error.message,
                });
            });
    }

    loadActionData(room_id, loading_key, data_key, limit=50, offset=0){
        const { auth } = this.props;

        this.setState({[loading_key]: true});

        return auth.fetch(`${API_URL}/rooms/${room_id}/actions/?limit=${limit}&offset=${offset}`)
            .then((response)=>{
                this.setState({
                    [loading_key]: false,
                    [data_key]: response.results,
                });
            })
            .catch((error) => {
                this.setState({
                    [loading_key]: false,
                    error: error.message,
                });
            });
    }

    loadGraphData(){

        const { graph_data, graph_action_data } = this.state;

        if (graph_data === undefined || graph_action_data === undefined){
            return;
        }

        // Deep copy to trigger graph render on chaning referential equality
        // https://github.com/kirjs/react-highcharts#limiting-highchart-rerenders
        let chart_config = JSON.parse(JSON.stringify(this.initial_chart_config));

        this.setState({graph_loading: true});

        const temp_series = graph_data.map( (reading) => {
            return [moment(reading.datetime).unix()*1000, reading.temperature];
        });

        const state_series = graph_data.map( (reading) => {
            return [moment(reading.datetime).unix()*1000, reading.state];
        });

        // Filter Actions to same time frame,
        // Add an action at the start and end of the selected time frame
        // so that the graph line covers the extent of the data
        const first_temp_reading = temp_series[temp_series.length-1][0];
        const last_temp_reading = temp_series[0];
        let action_series = graph_action_data.map( (action) => {
            return [moment(action.completed).unix()*1000, action.set_point];
        }).filter((dtsp) => dtsp[0] > first_temp_reading);
        if (action_series.length > 0) {
            const first_action_setpoint = action_series[action_series.length-1][1];
            const last_action = action_series[0];
            action_series.push([first_temp_reading, first_action_setpoint]);
            if (last_action[0] < last_temp_reading[0]){
                action_series.splice(0, 0, [last_temp_reading[0], last_action[1]]);
            }
        }


        chart_config.series.push({
            data: temp_series.reverse(),
            name: "Actual Temperature",
            yAxis: 0,
            color: "#B22222",
            tooltip: {
                pointFormat: '{series.name}: <b>{point.y:.2f} C</b><br/>',
            },
        });

        if (action_series.length > 0) {
            chart_config.series.push({
                data: action_series.reverse(),
                name: "Target Temperature",
                yAxis: 0,
                step: "left",
                color: "#1E90FF",
                tooltip: {
                    pointFormat: '{series.name}: <b>{point.y:.2f} C</b><br/>',
                },
            });
        }

        chart_config.series.push({
            data: state_series.reverse(),
            name: "Open State",
            yAxis: 1,
            color: "#888888",
            tooltip: {
                pointFormat: '{series.name}: <b>{point.y:.0f} %</b><br/>',
            },
        });

        this.setState({
            graph_loading: false,
            chart_config,
        });
    }

    sendAction(){
        const { set_point, data } = this.state;
        const { auth } = this.props;
        const { room } = data;
        const { user_id } = auth.getProfile();

        const headers = {
            "Accept": "application/json",
            "Content-Type": "application/json",
        }

        auth.fetch(`${API_URL}/actions/`, {
            method: "POST",
            body: JSON.stringify({
                room,
                user: user_id,
                set_point
            }),
            ...headers,
        })
        .then((response) => {
            this.setState({
                set_point_message: `New Setpoint Requested - ${set_point} C`,
            });
        })
        .catch((error) => {
            this.setState({
                error: error.errorMessage,
            });
        });
    }

    pageFunction(dir){
        const { count, limit, offset, data } = this.state;
        const { id } = data;
        let newOffset = offset;

        if (dir === "next"){
            if (offset < count){
                newOffset += limit;
            }
        } else if (dir === "prev"){
            if (offset > 0){
                newOffset = Math.max(0, offset - limit);
            }
        }

        if (newOffset !== offset){
            this.setState({offset: newOffset}, () => {
                this.loadReadingsData(id, "readings_loading", "readings_data", limit, newOffset );
            });
        }
    }


    renderHeader() {
        const { title, redirect, detailTitle } = this.props;
        return (
            <div className="row wrapper border-bottom white-bg page-heading">
                <div className="col-lg-12">
                    <h2>{title}</h2>
                    <ol className="breadcrumb">
                        <li><Link to="/">Home</Link></li>
                        <li><Link to={redirect}>{detailTitle}</Link></li>
                        <li className="active"><strong>Details</strong></li>
                    </ol>
                </div>
            </div>
        );
    }

    renderDetailsBlock(){
        const {
            data, loading, room_data, room_loading, floor_loading, floor_data,
            building_loading, building_data, gateway_loading, gateway_data
        } = this.state;
        const { auth } = this.props;
        const { name } = this.props.match.params;

        const is_empty = (obj) => obj === undefined || Object.keys(obj).length === 0 && obj.constructor === Object;

        const room_empty = is_empty(room_data);
        const floor_empty = is_empty(floor_data);
        const building_empty = is_empty(building_data);

        return (
            <div className="col-lg-4">
                <div className="ibox float-e-margins">
                    <div className="ibox-title">
                        <h5>Valve - {name}</h5>
                    </div>
                    <div className="ibox-content ValveDetailView-Box">
                        <div className="row">
                            {!loading && data ? (
                                <div className="ValveDetailView-ContentBoxContainer">
                                    <div className="row">
                                        <div className="col-lg-6"><strong>Room</strong></div>
                                        <div className="col-lg-6">
                                            { room_loading || room_empty ? (
                                                <Loading width={"14px"} height={"14px"}/>
                                            ) : (
                                                <span>{room_data.name}</span>
                                            )}
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-6"><strong>Floor</strong></div>
                                        <div className="col-lg-6">
                                            { floor_loading || floor_empty ? (
                                                <Loading width={"14px"} height={"14px"}/>
                                            ) : (
                                                <span>{floor_data.name}</span>
                                            )}
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-6"><strong>Building</strong></div>
                                        <div className="col-lg-6">
                                            { building_loading || building_empty ? (
                                                <Loading width={"14px"} height={"14px"}/>
                                            ) : (
                                                <span>{building_data.name}</span>
                                            )}
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-6"><strong>Associated Gateway</strong></div>
                                        <div className="col-lg-6">
                                            { gateway_loading ? (
                                                <Loading width={"14px"} height={"14px"}/>
                                            ) : (
                                                <span>{gateway_data.name}</span>
                                            )}
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-6"><strong>Commands</strong></div>
                                        <div className="col-lg-6">
                                            {!loading && data ? (
                                                <div>
                                                    <WorkflowButton auth={auth} valve={data.id} {...RESET_BUTTON_PROPS} />
                                                    <WorkflowButton auth={auth} valve={data.id} {...REFERENCERUN_BUTTON_PROPS} />
                                                    <WorkflowButton auth={auth} valve={data.id} {...TEACHIN_BUTTON_PROPS} />
                                                    {data.version !== "3" && (
                                                        <WorkflowButton auth={auth} valve={data.id} {...CONFIGPIR_BUTTON_PROPS} />
                                                    )}
                                                </div>
                                            ) : (
                                                <Loading width={"14px"} height={"14px"}/>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <div className="row">
                                    <div className="col-lg-4 col-lg-offset-5">
                                        <Loading width={"50px"} height={"50px"} />
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderLastReadingBlock(){
        const { readings_data, readings_loading, room_loading, room_data } = this.state;

        const is_empty = (obj) => obj === undefined || Object.keys(obj).length === 0 && obj.constructor === Object;
        const room_empty = is_empty(room_data);

        // Get latest reading
        let reading = null;
        let batteryIcon = <Loading width={"14px"} height={"14px"}/>;
        if (readings_data && readings_data.length > 0){
            reading = readings_data[0];

            // Decide how to render the power charging.
            if (reading.is_charging){
                batteryIcon = <FontAwesome name="battery-charging" fixedWidth title="Charging" />;
            }
            else if (reading.is_battery_low){
                batteryIcon = <FontAwesome name="battery-quarter" fixedWidth title="Battery Low" />;
            }
            else{
                batteryIcon = <FontAwesome name="battery-full" fixedWidth title="Battery Ok" />;
            }
        }


        return (
            <div className="col-lg-4">
                <div className="ibox float-e-margins">
                    <div className="ibox-title">
                        <h5>Last Status</h5>
                    </div>
                    <div className="ibox-content ValveDetailView-Box">
                        <div className="row">
                            {!readings_loading && reading ? (
                                <div className="ValveDetailView-ContentBoxContainer">
                                    <div className="row">
                                        <div className="col-lg-6"><strong>Received at Gateway</strong></div>
                                        <div className="col-lg-6">
                                            <Moment date={reading.datetime} format={"YYYY/MM/DD HH:mm"} />
                                            <br/> ({moment(reading.datetime).fromNow()})
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-6"><strong>Requested Temperature</strong></div>
                                        <div className="col-lg-6">
                                            { room_loading || room_empty ? (
                                                <Loading width={"14px"} height={"14px"}/>
                                            ) : (
                                                <span>{room_data.set_point} &deg;C</span>
                                            )}
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-6"><strong>Current Temperature</strong></div>
                                        <div className="col-lg-6">
                                            {reading.temperature.toFixed(2)} &deg;C
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-6"><strong>Current Open State</strong></div>
                                        <div className="col-lg-6">
                                            {reading.state} %
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-6"><strong>Power</strong></div>
                                        <div className="col-lg-6">
                                            {batteryIcon}
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <div className="row">
                                    <div className="col-lg-4 col-lg-offset-5">
                                        <Loading width={"50px"} height={"50px"} />
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    clearSetPointMessage(){
        this.setState({set_point_message: ""});
    }

    renderRequestActionFormBlock(){
        const { data, set_point_message } = this.state;
        return (
            <div className="col-lg-4">
                <div className="ibox float-e-margins">
                    <div className="ibox-title">
                        <h5>Request Temperature</h5>
                    </div>
                    <div className="ibox-content ValveDetailView-Box">
                        <div className="row">
                            <div className="ValveDetailView-ContentBoxContainer">
                                <div className="row">
                                    <div className="col-lg-6"><strong>{this.actionFormData.name}</strong></div>
                                    <div className="col-lg-6">
                                        {renderInputNumber(this.actionFormData)}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-11">
                                        <div className="pull-right">
                                            { !data ? (
                                                <Loading width={"20px"} height={"20px"} />
                                            ) : (
                                                <button className="btn btn-sm btn-success" type="submit" onClick={this.sendAction}>Set</button>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        { set_point_message && (
                            <div className="alert alert-success animated lightSpeedIn ValveDetailView-alert">
                                <FontAwesome name="times" onClick={this.clearSetPointMessage} /> &nbsp;
                                    {set_point_message}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }

    renderGraphBlock(){
        const { graph_loading, chart_config } = this.state;
        return (
            <div className="col-lg-12">
                <div className="ibox float-e-margins">
                    <div className="ibox-title">
                        <h5>Graph</h5>
                        { graph_loading && <Loading className="pull-right" width={"14px"} height={"14px"} /> }
                    </div>
                    <div className="ibox-content">
                        <div className="row">
                            <HighCharts config={chart_config} isPureConfig />
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    render() {

        const { readings_data, loading, readings_loading, count, offset, limit } = this.state;
        const { data_rows } = this.props;

        let totalPages = Math.ceil(count / limit);
        let page = (offset / limit) + 1;

        if (loading) {
            return (
                <div>
                    {this.renderHeader()}
                    <div className="wrapper wrapper-content">
                        <div className="row">
                            <div className="col-lg-4 col-lg-offset-5">
                                <Loading width={"100px"} height={"100px"} />
                            </div>
                        </div>
                    </div>
                </div>
            )
        }

        return (
            <div>
                {this.renderHeader()}
                <div className="wrapper wrapper-content animated fadeIn">
                    <div className="row">
                        {this.renderDetailsBlock()}
                        {this.renderLastReadingBlock()}
                        {this.renderRequestActionFormBlock()}
                    </div>
                    <div className="row">
                        {this.renderGraphBlock()}
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <Table
                                title={"Valve Readings"} loading={readings_loading}
                                data={readings_data} data_rows={data_rows} no_action={true}
                                page={page} totalPages={totalPages} pageFunction={this.pageFunction} top_paginate={true}
                            />
                        </div>
                    </div>


                </div>
            </div>
        )
    }

}

export default ValveDetailView;
