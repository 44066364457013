import React, { Component } from 'react';
import { API_URL } from '../../config';

import Loading from './Loading';

import './SelectRestList.css';

class SelectRestList extends Component {

    constructor(props){
        super();

        this.state = {
            loading: false,
            data: null,
        };

        this.fetchData = this.fetchData.bind(this);
    }

    componentWillMount(){
        const {endpoint, value_key, label_key } = this.props;
        this.fetchData(endpoint, value_key, label_key);
    }

    componentWillReceiveProps(nextProps){
        const {endpoint, value_key, label_key } = nextProps;
        const old_endpoint = this.props.endpoint;
        if (old_endpoint !== endpoint)
            this.fetchData(endpoint, value_key, label_key);
    }

    fetchData(endpoint, value_key, label_key){

        const { auth } = this.props

        this.setState({ loading: true });
        auth.fetch(`${API_URL}/${endpoint}`)
            .then( (response) => {
                this.setState({
                    loading: false,
                    data: response.results
                });
            })
            .catch((error) => {
                this.setState({
                    loading: false,
                    error: error.message,
                });
            });
    }

    render(){
        const { loading, data} = this.state;
        const { api_name, value_key, label_key, change, value} = this.props;

        if (loading){
            return (
                <div>
                    <select className="form-control SelectRestList-Select" disabled>
                        <option value="" disabled selected>Loading...</option>
                    </select>
                    <span className="SelectRestList-Loading"><Loading width={"20px"} height={"20px"} /></span>
                </div>
            )
        }

        return (
            <select name={api_name} className="form-control SelectRestList-Select" onChange={change} >
                {!value && <option value="" disabled selected>Please select a value</option>}
                {
                    data.map( (object, index) => {
                            if (value && object[value_key] === value) {
                                return <option key={index} value={object[value_key]} selected>{object[label_key]}</option>
                            } else {
                                return <option key={index} value={object[value_key]}>{object[label_key]}</option>
                            }
                    })
                }
            </select>
        )
    }
}

export default SelectRestList;
