import React from 'react';

const Footer = () => {
    return (
        <div className="footer">
            <div className="pull-right">
                100 Active Valves.
            </div>
            <div>
                <strong>Copyright</strong> Aeriform Tech &copy; 2019
            </div>
        </div>
    )
}

export default Footer
